/********************************/
/*            Sizes             */
/********************************/
@default-size: 16px;

/********************************/
/*           Colors             */
/********************************/
@primary-blue: #002C6C;
@primary-orange: #F26334;
@primary-dark-gray: #454545;
@primary-dark-medium-gray: #888B8D;
@primary-light-medium-gray: #B1B3B3;
@primary-light-gray: #f4f4f4;
@secondary-raspberry: #F05587;
@secondary-link: #008DBD;

@selected-orange: rgba(242, 99, 52, 0.15);

@form-warning: #f44336;
@form-info: #fbb034;
@form-valid: #59b29e;


@popup-back-ground: #262626;
@popup-title: #002C6C;
/********************************/
/*     Responsive utilities     */
/********************************/
@screen-lg-min: 1200px;
@screen-md-min: 992px;
@screen-sm-min: 768px;

/********************************/
/*      Utilities mixins        */
/********************************/
._flex(@value) {
  -webkit-box-flex: @value;
  -webkit-flex: @value;
  -ms-flex: @value;
  flex: @value;
}

._flex-direction (@value) {
  -webkit-flex-direction: @value;
  -ms-flex-direction: @value;
  flex-direction: @value;
}

._flex-display {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

._transition(@arguments) {
  -webkit-transition: @arguments;
  -moz-transition: @arguments;
  -ms-transition: @arguments;
  -o-transition: @arguments;
  transition: @arguments;
}

/********************************/
/*           Mixins             */
/********************************/
.bold {
  font-weight: 700;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Arrows for popover
.arrowBottom(@left, @right, @color, @borderColor) {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  left: @left;
  right: @right;
  margin-left: -11px;
  border: 11px solid transparent;
  border-top-color: @borderColor;
  border-bottom-width: 0;
  bottom: -11px;

  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border: 10px solid transparent;
    border-top-color: @color;
    border-bottom-width: 0;
  }
}

.arrowTop(@left, @right, @color, @borderColor) {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: -11px;
  left: @left;
  right: @right;
  margin-left: -11px;
  border: 11px solid transparent;
  border-top-width: 0;
  border-bottom-color: @borderColor;

  &:after {
    position: absolute;
    display: block;
    top: 1px;
    margin-left: -10px;
    width: 0;
    height: 0;
    content: " ";
    border: 10px solid transparent;
    border-top-width: 0;
    border-bottom-color: @color;
  }
}

/* Buttons */
.gs1Button(@color:white, @backgroundColor: @primary-blue, @height: 35px) {
  border: none;
  height: @height;
  color: @color;
  background-color: @backgroundColor;

  &[disabled],
  &.disabled {
    opacity: 0.8;
    cursor: default;
  }
  &:focus {
    outline: none;
  }
}

.gs1Button-popup(@color: white, @backgroundColor: #CD3C0D, @height: 35px){
  border: none;
  height: @height;
  color: @color;
  background-color: @backgroundColor;
  border-radius: 4px;

  &[disabled],
  &.disabled {
    opacity: 0.8;
    cursor: default;
  }
  &:focus {
    outline: none;
  }
}
.gs1Button--withIcon(@color:white, @backgroundColor: @primary-blue) {
  .gs1Button(@color, @backgroundColor);
  font-size: 0.8rem;
  padding: 0 10px 0 5px;
  vertical-align: bottom;
}

.gs1Button--Clear(@color: @primary-blue) {
  .gs1Button(@color, transparent, initial);
  ._transition(background-color 0.2s);
  background-color: transparent;

  &[disabled],
  &.disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: @primary-light-gray;
  }
}

.radio {
  height: auto !important
}

.gs1Input {
  padding: 4px 10px 4px 10px;
  width: 100%;
  border: 1px solid #E1E1E1;
  -webkit-transition: border 0.3s;
  -moz-transition: border 0.3s;
  -ms-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
  border-radius: 8px;
  padding-right: 25px;
  border-color: #B1B3B3;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
    border-color: #B1B3B3;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2) !important;
  }
}


.gs1TextArea {
  .gs1Input;
}

.gs1Select {
  .gs1Input;
  padding: 0 30px 0 10px;
  height: 50px;
  line-height: 30px;
  background: transparent url('../icons/selectIcon.svg') no-repeat right;
  -webkit-appearance: none;
}

.smallInput {
  .gs1Input;
  border: 1px solid #E1E1E1;
  -webkit-transition: border 0.3s;
  -moz-transition: border 0.3s;
  -ms-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
  border-radius: 4px;
  border-color: #B1B3B3;
  transition: all 0.2s ease-in-out;
  /* padding-right: 25px; */
  width: auto;
}
