@import './variables';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';


html, head, body {
  font-family: 'Gotham SSm A', 'Gotham SSm B', 'Verdana', 'sans-serif';
  font-weight: 300;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

body:not(.searchBody) {
  min-width: 1200px;
}

/* Global css */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

h1, h2 {
  font-weight: 400;
}

.blue {
  color: #002c6c;
}

.help-subtitle {
  font-size: 17px;
  font-weight: 400;
}

/*************************************/
/*       Notifications styles        */
/*************************************/
.simple-notification.alert {
  background: @form-info !important;
}

.simple-notification {
  &.noGtinAvailable {
    .sn-content {
      padding: 0 !important;
      font-size: 14px !important;
      text-align: justify !important;
    }
  }
}

.simple-notification {
  &.categoryUpdated {
    //background: inherit!important;
    //background-color: #d3f0dd!important;
    .sn-content {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      text-align: justify !important;
    }
  }
}

.app-notifications .simple-notification-wrapper {
  width: auto;
  max-width: 400px;

  .gs1Notification {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    bottom: -20px;

    .title {
      font-size: 1.2rem;
      line-height: 24px;
      font-weight: 400;
      white-space: pre-line;
      margin-right: 30px;
    }

    .content {
      font-size: 0.9rem;
      white-space: pre-line;
    }
  }
}

// To remove IE10+ clear button
input::-ms-clear {
  display: none;
}

/* Flex grid */
.grid-row {
  box-sizing: border-box;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.grid-cell {
  display: table-cell;
  padding: 8px;
  ._flex(1);
  ._flex-direction(column);
  position: relative;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@supports (display: flex) {
  .grid-row {
    display: flex;
    border-bottom: 1px solid @primary-light-gray;
  }

  .grid-inner-row {
    .grid-row;
    width: ~'calc(100% - 60px)';
    align-items: center;
  }

  // display: block is needed to allow inline-block content inside a grid-cell, if there is vertical alignment, we need display: flex
  .grid-cell {
    display: block;
  }

  .grid-cell-valign-middle,
  .grid-cell-valign-bottom {
    display: flex;
  }
}

.grid-cell-min {
  width: 1%;
  flex-basis: auto;
}

.grid-cell-max {
  width: 100%;
  flex: 1 100%;
}

/*************************************/
/*          Popover styles           */
/*************************************/
.icon-help {
  display: inline-block;
  height: 35px;
  width: 35px;
  vertical-align: bottom;
  background: url('../../assets/icons/help.svg') no-repeat;

  &:hover {
    background: url('../../assets/icons/helpActive.svg') no-repeat;
  }
}

.icon-help.min {
  height: 30px;
  width: 30px;
}

.popover {
  max-width: 400px;
  padding: 0;
  border-radius: 0;

  p {
    margin: 0;
    white-space: pre-line;
  }

  .popover-title {
    border-bottom: none;
    border-top: 8px solid @primary-orange;
    background-color: white;
    border-radius: 0;
    font-weight: 300;
    font-size: 1.2rem;
  }

  .popover-content {
    font-size: 1rem;
    font-weight: 300;
    padding: 0 15px 15px;
    text-align: justify;
    white-space: pre-line;
    background-color: white;
  }

  &.right.popover-right,
  &.left.popover-left {
    top: -35px !important;

    .arrow {
      z-index: 2000;
      top: 50px;
    }
  }

  .row:last-child {
    display: none;
  }
}

// Specific popovers
.completionLevel .popover.top.popover-top {
  left: 78px !important;

  .arrow {
    left: 73px;
  }
}

/*************************************/
/*            Modal styles           */
/*************************************/
.modal-backdrop {
  background-color: @popup-back-ground;

  &.in {
    opacity: 80%;
  }
}

.modal {
  .modal-header {
    padding-bottom: 0;
    border-bottom: 1px solid @primary-light-medium-gray;
  }

  .modal-footer {
    border-top: none;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-title {
    font-size: 1.3rem;
    color: @primary-blue;
    .bold;
  }

  .close {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 1.8rem;
    font-weight: 400;
    padding: 0;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }
}
.modal.productsExportModal{
  .modal-footer {
    padding-top: 30px;
  }
}
// Barcode generation Modal
.modal.barcodeModal {
  .modal-dialog {
    max-width: 800px;
    width: 95%;
  }
}

// Application Disclaimer Modal
.modal.applicationDisclaimerModal {
  .modal-dialog {
    max-width: 700px;
    width: 80%;
    margin-top: 100px;
  }
}

.modal.disclaimerModal {
  .modal-dialog {
    margin-top: 200px;
    @media (min-width: 768px) {
      width: 350px;
    }
  }

  .modal-body {
    background-color: @primary-orange;
    color: white;
    padding: 30px 20px;
  }
}

.modal.confirmationModal {
  .modal-dialog {
    margin-top: 200px;
    @media (min-width: 768px) {
      width: 350px;
    }
  }

  .modal-body {
    background-color: @primary-orange;
    color: white;
    padding: 30px 20px;
  }
}
.modal.nonpayer {
  .modal-content {
    margin-top: 200px;
    @media (min-width: 768px) {
      width: 400px;
    }
  }
  .modal-body {
    padding: 20px 20px;
  }

  .modal-footer {
    padding-bottom: 5px !important;
  }
  .toggleNutriScore {
    font-size: 12px;
  }
  .submitButton {
    width: 170px !important;
  }
}
.modal.tutorialModal {
  .modal-dialog {
    max-width: 600px;
    width: 80%;
    margin-top: 100px;
  }

  // Carousel
  .carousel-inner {
    padding: 30px 85px 5px;
  }

  .carousel-indicators {
    bottom: -70px;

    li {
      margin: 1px 5px;

      &.active {
        margin: 0 5px;
      }
    }
  }

  .carousel-control {
    opacity: 1;
    cursor: pointer;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 40%;

    &.disabled {
      display: none;
    }

    span {
      display: none !important;
    }

    &.right {
      background: url('../../assets/icons/rightBlueArrow.svg') center no-repeat;
      right: -80px;
    }

    &.left {
      background: url('../../assets/icons/leftBlueArrow.svg') center no-repeat;
      left: -80px;
    }
  }
}

.modal.ShareModal {
  .modal-dialog {
    margin-top: 150px;

    .modal-content {
      border-radius: 10px;

      .modal-header {
        border-bottom: 0;

        .close {
          z-index: 1000;
        }
      }
    }
  }
}

.modal.productsImportModal {
  .modal-dialog {
    margin-top: 150px;

    .modal-content {
      border-radius: 10px;

      .modal-header {
        border-bottom: 0;
      }
    }
  }
}

.modal.productImportConfirmationModal {
  .modal-dialog {
    margin-top: 70px;

    .modal-content {
      .modal-header {
        padding-bottom: 15px;
        border-bottom: 1px solid #e5e5e5;
      }

      .modal-footer {
        border-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

.modal.classificationModal {

  .modal-dialog {
    margin-top: 150px;

    .modal-content {
      border-radius: 10px;

      .modal-header {
        border-bottom: 0 !important;
      }

      .modal-title {
        margin-bottom: 17px;
        color: @primary-blue;
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}

.modal.gs1Modal {
  z-index: 99999;

  .modal-body {
    position: relative;
    padding: 15px 33px 11px;
    text-align: justify;
  }
  .modal-dialog {
    margin-top: 201px;
    width: 540px;
    .modal-content {
      border-radius: 4px;
      border: 1px solid #e5f0fc;

      .modal-header {
        border-bottom: 0 !important;
      }

      .modal-title {
        margin-bottom: 17px;
        color: @primary-blue;
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}

.modal.onBoardingModal {
  .modal-header {
    font-family: 'Gotham SSm A', 'Gotham SSm B', 'Verdana', 'sans-serif';
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 0.3px;
    color: @primary-blue;
    text-align: left;
    padding: 15px;
  }

  .modal-footer {
    .OnBoardingModal-carousel-footer-next {
      .gs1Button(white, @primary-orange);
      float: right;
      padding: 10px 20px;
      border-radius: 2px;
      height: auto !important;
      font-size: 13px;
      border: 1px solid @primary-orange;
      margin-bottom: 10px;
      font-family: 'Gotham SSm A', 'Gotham SSm B', 'Verdana', 'sans-serif';
    }

    .OnBoardingModal-carousel-footer-dismiss {
      .gs1Button(@primary-light-medium-gray, white);
      float: left;
      padding: 10px 20px;
      border-radius: 2px;
      height: auto !important;
      font-size: 13px;
      border: 1px solid @primary-light-medium-gray;
      margin-bottom: 10px;
      font-family: 'Gotham SSm A', 'Gotham SSm B', 'Verdana', 'sans-serif';
    }
  }

  .modal-dialog {
    max-width: 600px;
    width: 80%;
    margin-top: 100px;
  }

  // Carousel
  .carousel-inner {
  }

  .carousel-indicators {
    bottom: -70px;

    li {
      margin: 1px 5px;
      background-color: @primary-dark-medium-gray;

      &.active {
        background-color: @primary-orange;
        margin: 0 5px;
      }
    }
  }

  .carousel-control {
    opacity: 1;
    cursor: pointer;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 40%;
    visibility: hidden;

    &.disabled {
      display: none;
    }

    span {
      display: none !important;
    }

    &.right {
      background: url('../../assets/icons/rightBlueArrow.svg') center no-repeat;
      right: -80px;
    }

    &.left {
      background: url('../../assets/icons/leftBlueArrow.svg') center no-repeat;
      left: -80px;
    }
  }
}

// disable arrow for dropdown list
.gs1selectize-input {
  background: none !important;
}

ng-selectize {
  .gs1selectize-input {
    padding-top: 7px !important;
    min-height: 50px;
    border-radius: 8px !important;
    border: 1px solid #e1e1e1;

    .item {
      height: 35px !important;
      line-height: 32px;
      background-color: #002c6c !important;
      border-radius: 4px !important;
      color: #ffffff !important;
      font-weight: bold !important;
      padding: 0 25px 0 10px;
    }
  }

  padding-left: 15px;
}

popover-container {
  width: 100%;
}

.popover .popover-content {
  font-size: 0.9rem;
}

.popover.right.popover-right .arrow,
.popover.left.popover-left .arrow {
  display: none;
}

.classificationCategoriesModal {
  .modal-dialog {
    width: 420px;
    height: 562px;
    top: 70px;

    .modal-content {
      border-radius: 6px;

      .classificationCategoriesModal-header {
        .modal-header {
          border: none;
        }

        .classificationCategoriesModal-title {
          color: #f26334;
          font-size: 18px;
        }
      }

      .classificationCategoriesModal-body {
        // .classificationCategoriesModal-header {
        //   color: #F26334;
        //   font-size: 18px;
        // }
        .code-category {
          padding-bottom: 22px;
          color: #888b8d;
        }

        .def-category {
          padding-bottom: 60px;
        }
      }

      .classificationCategoriesModal-footer {
        .modal-footer {
          border: none;
        }
      }
    }
  }
}

.ClassificationOnBoardingModal {
  .modal-content {
    position: relative;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  .modal-content:before {
    content: "";
    position: absolute;
    right: 99.5%;
    top: 80%;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-right: 26px solid #ffffff;
    border-bottom: 13px solid transparent;
  }

  .modal-header {
    border-bottom: 0;
    color: #888b8d;
    font-size: 12px;
    padding-left: 33px;
    padding-top: 25px;
  }

  .modal-body {
    padding-top: 0;
    padding-left: 33px;
  }

  .modal-footer {
    border-top: 0;
  }
}

.publishingDescriptionModal {
  top: 67px;
}

.modal-dialog.publishing-description-modal-content > .modal-content {
  // background-color: #fce0d6;
  border-radius: 17px;
  background-image: url('../../assets/icons/digitalisez-produits.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: contain;
}

.modal-dialog.publishing-description-modal-content,
  > .modal-content,
  .modal-header {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  // background-color: #fce0d6;
  border-bottom: none;
  padding-top: 30px;
}

.modal-dialog.publishing-description-modal-content,
  > .modal-content,
  .modal-body {
  padding: 100px;
  padding-top: 34px;
  padding-bottom: 34px;
}

.ClassificationSecondOnBoardingModal {
  .modal-content {
    position: relative;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  .modal-content:before {
    content: "";
    position: absolute;
    right: 99.5%;
    top: 80%;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-right: 26px solid #ffffff;
    border-bottom: 13px solid transparent;
  }

  .modal-header {
    border-bottom: 0;
    color: #888b8d;
    font-size: 12px;
    padding-left: 33px;
    padding-top: 25px;
  }

  .modal-body {
    padding-top: 0;
    padding-left: 33px;
  }

  .modal-footer {
    border-top: 0;
    padding-top: 0;
  }
}

.FoodOnBoardingModalModal {
  font-size: 12px;

  .modal-content {
    padding: 15px;
    border-radius: 10px;
  }

  .modal-header {
    border-bottom: 0;
    margin-left: 33px;
    color: #888b8d;
  }

  .modal-body {
    padding-top: 0;
    margin-left: 33px;
  }

  .modal-footer {
    border-top: 0;
    padding-top: 20px;
  }
}

.qrcode-modal {
  .modal-content{
    height: 750px;
    padding: 5px 30px;
  }
}

.switch.switch-medium {
  background: @primary-orange;
}

.switch.checked {
  background: #64bd63 !important;
}

span.import {
  color: @primary-orange !important;
  font-weight: bold;
}

span.makeBold {
  font-weight: bold;
}

.search-engine {
  width: 70%;
}


.brandAlerte{
  .warning-box {
    margin-left: 0 !important;
  }
}


.mat-tooltip{
  overflow: visible!important;
  background-color: #414345;
  font-size: 12px;
  line-height: 1.33;
  //@include rfs.font-size((gs1-vars.$font-size-little));
  &.table-tooltip {
    background-color: red;
    transform-origin: left bottom;
  }

  // &:before{
  //   content: "";
  //   border-style: solid;
  //   border-width: 10px 10px 0px 10px;
  //   border-color: #414345 transparent transparent transparent;
  //   position: absolute;
  //   left:50%;
  //   transform: translateX(-50%);
  //   bottom: -7px;
  // }
}

.mat-tooltip-bottom-arrow-top-right {
  &:before{
    content: "";
    border-style: solid;
    border-width: 0px 10px 10px 10px;
    border-color: transparent transparent #414345 transparent;
    position: absolute;
    left: 93%;
    transform: translateX(-50%);
    top: -10px;
  }
}

.mat-tooltip-bottom-arrow-top-mid {
  &:before{
    content: "";
    border-style: solid;
    border-width: 0px 10px 10px 10px;
    border-color: transparent transparent #414345 transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
  }
}

.mat-tooltip-right-arrow-left-mid {
  &:before{
    content: "";
    border-style: solid;
    border-width: 10px 10px 10px 0px;
    border-color: transparent #414345 transparent transparent;
    position: absolute;
    top: 35%;
    transform: translateX(0%);
    left: -10px;
  }
}
.input-accept-cgu{
  transform: scale(1.5);
  margin-right: 5px;
}

.cgu-disclaimer{
  margin-top: 40px ;
}

.panel-class .mat-dialog-container{
  overflow: hidden;
}
