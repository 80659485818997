@use "~@gs1france/gs1-components-library/assets/styles/standards/variables" as gs1-vars;
@use "~rfs/scss" as rfs with (
  $rfs-base-value: gs1-vars.$rfs-base-value,
  $rfs-factor: gs1-vars.$rfs-factor,
  $rfs-breakpoint: gs1-vars.$rfs-breakpoint
);
@use "~@gs1france/gs1-components-library/assets/styles/standards/colors" as gs1-colors;
@use "~@gs1france/gs1-components-library/assets/styles/standards/normalize-custom";
@use "~@gs1france/gs1-components-library/assets/styles/standards/type";
@use "~@gs1france/gs1-components-library/assets/styles/standards/utilities";

// Librairies imports
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~bootstrap-icons/font/bootstrap-icons';
@import "~@gs1france/gs1-components-library/style"; // You can import only submodules scss if you don't need everything;

.gs1-default-size, body {
  font-size: 14px;
}

//.Unity-form-section {
//  .gs1-link-card {
//
//  }
//}

.ingredientTextAreaFooterAlert {
  h5 {
    font-size: 14px;
  }
}

.gs1-alert-qr-code {
  h5 {
    font-size: 12px;
  }
  .bi-arrow-right::before {
    color: #00799e;
  }
  .bi-arrow-right:hover:before {
    color: #002C6C;
  }


  a:visited, a:focus, a:active {
    color: #00799e;
    text-decoration: none;
    cursor: pointer;
  }

  a:hover{
    color: #002C6C;
    text-decoration: none;
    cursor: pointer;
    .bi-arrow-right::before {
      color:  #002C6C;
    }
  }
}


.reglementary-success-alert{
  background-image: url('/assets/img/check.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  color: #008037;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 44, 108, 0.2);
  border: solid 1px #bee8cd;
  border-left-color: #bee8cd !important;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;

  i{
    font-size: 11px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.09;
  }
}
